function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="white">
        LET'S WORK<br />
        TOGETHER
        
        <span style={{fontSize: "0.3em", marginTop: "2em"}}>
            <a target="_blank" href="https://maps.app.goo.gl/MuVPBxMFGFLBcBYM8">Via Pietro Crespi 6, Milano</a><br />
            <a href="mailto:info@naturalvoice.it">info@naturalvoice.it</a><br />
            <a target="_blank" href="https://wa.me/+393338501757">+39 333 8501757</a>
        </span>
    </div>
  );
}

function Contacts() {
  return (
    <div id="contacts">
      {Slide1()}
    </div>
  );
}

export default Contacts;
