function Video(){
  return (
    <>
     <video playsInline autoPlay muted id="homeVid" data-hc="white">
      <source src="https://www.naturalvoice.it/static/Natural_Voice_HD.mp4" type="video/mp4" />
     </video>
    </>
  );
}

function Slide1(){
  return (
    <div className="slide slide1 padded" style={{backgroundColor: 'white'}} data-hc="orange">
      <div style={{backgroundColor: 'white'}}>
        <p className="notop">Natural Voice&reg; &egrave; l'unico studio di voce in Italia<br />che rinuncia a qualsiasi metodo standardizzato.</p>
        <p className="nobottom">Celebra e valorizza l'Autenticit&agrave; e l'Unicit&agrave;!</p>
      </div>
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" style={{}} data-hc="white">
      <div className="columnsContainer">
        <div className="column c0">
          <img src="logosfull/natural_voice_color_bianco.png" />
          <p className="subtitle c0">PARLATA</p>
          <p className="content">
            <p>
            La voce &egrave; il nostro biglietto da visita.
            Rivela emozioni, intenzioni e stati d'animo.
            Gestirla &egrave; cruciale per una comunicazione efficace, per trasmettere non solo il messaggio, ma anche la nostra identit&agrave;.
            </p>
            <p>
            Nel public speaking scommettiamo sull'autenticit&agrave; e naturalezza, ritenendole pi&ugrave; genuine e d'impatto.
            </p>
          </p>
        </div>
        <div className="column c1">
          <img src="logosfull/natural_voice_color_bianco.png" />
          <p className="subtitle c1">CANTATA</p>
          <p className="content">
            <p>
              Il nostro obiettivo &egrave; guidare gli artisti nella riscoperta della propria voce naturale per renderla pi&ugrave; riconoscibile tra tante.
            </p>
            <p>
              Lo facciamo attraverso esercizi innovativi e un approaccio interdisciplinare brevettato Natural Voice&reg;
            </p>
          </p>
        </div>
        <div className="column c2">
          <img src="logosfull/natural_voice_color_bianco.png" />
          <p className="subtitle c2">AZIENDE</p>
          <p className="content">
            <p>
              Comunicare bene per un'azienda significa avere successo. 
            </p>
            
            <p>
              Corsi di formazione vocale come potente strumento di team building per dipendenti e dirigenti. Organizziamo sessioni professionali di public speaking, per una comunicazione aziendale interna ed esterna efficace centrata sul successo duraturo.
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}

function Slide3(){
  return (
    <div className="slide slide3 padded" style={{}} data-hc="white">
      <div className="column c0">
        <img src="IMG_5984.jpg" />
        <div className="mv">Marco Volpato<br/>Founder of Natural Voice&reg;</div>
      </div>
      
      <div className="column c1">
        <div>
          <a href="/us">ABOUT US</a>
        </div>
        
        <div className="mv mobile">Marco Volpato<br/>Founder of Natural Voice&reg;</div>
        
        <div className="small">
           <a href="/us">scopri di pi&ugrave;</a>
        </div>
      </div>
    </div>
  );
}

function Slide5(){
  return (
    <div className="slide slide5 padded" style={{}} data-hc="orange">
      <div className="column c0">
        <a href="/contacts">
         GET IN<br/>
         TOUCH<br/>
        </a>
      </div>
    </div>
  );
}

function Homepage() {
  return (
    <>
      {Video()}
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide5()}
    </>
  );
}

export default Homepage;
