function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="white">
     Natural Voice&reg;
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" data-hc="orange">
      Rivoluziona la tua voce<br />
      Trasforma la tua comunicazione
    </div>
  );
}

function Slide3(){
  return (
    <div className="slide slide3 padded" style={{}} data-hc="white">
      <div className="text-column">
        <div className="content">
          <p>
          Natural Voice&reg; nasce per dare voce alla propria voce,
          liberarla dalle costrizioni che ci si porta dietro
          dall’infanzia e riportarla al suo stato naturale,
          per scoprirne tutti gli aspetti e
          potenziarla rispettando la propria
          natura avendone cura.
          </p>
        </div>
      </div>
      {/*<div className="logos-column">
        <img style={{opacity: "1"}}src="logosfull/natural_voice_color_bianco.png" />
        <img style={{opacity: "0.6"}} src="logosfull/natural_voice_color_bianco.png" />
        <img style={{opacity: "0.4"}} src="logosfull/natural_voice_color_bianco.png" />
        <img style={{opacity: "0.2"}} src="logosfull/natural_voice_color_bianco.png" />
      </div>*/}
    </div>
  );
}

function Slide4(){
  return (
    <div className="slide slide4" data-hc="orange">
      <div className="logo">
        <img src="./natural_voice_nv_01.png" />
        {<>
        <div style={{top: "40px", left: "5px"}} className="label">Fattori Morfologici</div>
        <div style={{top: "45px", left: "460px", width: "29rem"}} className="label">Psicologia della Voce</div>
        <div style={{top: "420px", left: "-75px"}} className="label">Neuroscienze</div>
        <div style={{top: "330px", left: "347px"}} className="label">Emozione</div>
        <div style={{top: "370px", left: "670px", width: "23rem"}} className="label">Contesto Socio-Culturale</div>
        </>
        }
      </div>
    </div>
  );
}

function Slide7(){
  return (
    <div className="slide slide3 slide7 padded" data-hc="white">
      <div>
        <p>
          Natural Voice&reg; &egrave; un approccio innovativo che fonde la conoscenza
          dell’anatomia, della fisica acustica, delle
          neuroscienze e della psicologia per liberare la voce
          dalle costrizioni fisiche, emotive e socio-culturali in
          favore della sua unicità.
        </p>
        <p>
          Accompagniamo chiunque voglia migliorare la propria comunicazione artistica, professionale e individuale attraverso la VOCE NATURALE, poiché questa rimane il focus principale su cui si basa la nostra filosofia.
        </p>
      </div>
    </div>
  );
}

function Slide8(){
  return (
    <div className="slide slide8 padded" data-hc="orange">
      <div>
        <p>
          Unisciti a noi e
          scopri come Natural Voice&reg;
          pu&ograve; trasformare la
          tua voce e la tua comunicazione.
        </p>
      </div>
    </div>
  );
}

function NaturalVoice() {
  return (
    <div id="naturalvoice">
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide4()}
      {Slide7()}
      {Slide8()}
    </div>
  );
}

export default NaturalVoice;
