function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="white">
      <div class="abusheader">ABOUT US</div>
      <div className="marco">
        <div>
          <img src="IMG_5984.jpg" />
        </div>
        
        <div>
          <span class="marcomarco">Marco Volpato</span><br /><br />
          Founder of Natural Voice&reg;<br />
          dal 2020 dopo anni di<br />
          esperienza nel settore musicale,<br />
          della comunicazione e della fisica.

        </div>
      </div>
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" data-hc="orange">
        <div className="claim">
        NaturalVoice&reg; &egrave; il primo
        studio di voce in Italia che
        rinuncia a qualsiasi
        metodo standardizzato in
        favore dell’unicità
        </div>
    </div>
  );
}

function Slide3(){
  return (
    <div className="slide slide3 padded" data-hc="orange">
        <div className="claim">
        Il nostro obiettivo è quello
        di ottenere il massimo
        risultato con il minimo
        sforzo e nel minor tempo
        possibile
        </div>
    </div>
  );
}

function Slide4(){
  return (
    <div className="slide slide4 padded" data-hc="white">
        <div className="claim">
          <a href="contacts">
            Vieni a trovarci nella nuova sede ufficiale<br/>Natural Voice&reg;
            a Milano in Via Pietro Crespi 6
          </a>
        </div>
    </div>
  );
}


function AboutUs() {
  return (
    <div id="aboutus">
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide4()}
    </div>
  );
}

export default AboutUs;
