import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Homepage from './components/Homepage';
import NaturalVoice from './components/NaturalVoice';
import VoceCantata from './components/VoceCantata';
import VoceAziende from './components/VoceAziende';
import Contacts from './components/Contacts';
import VoceParlata from './components/VoceParlata';
import AboutUs from './components/AboutUs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App><Homepage /></App>,
  },
  {
    path: "/naturalvoice",
    element: <App><NaturalVoice /></App>,
  },
  {
    path: "/naturalvoice/cantata",
    element: <App><VoceCantata /></App>,
  },
  {
    path: "/naturalvoice/parlata",
    element: <App><VoceParlata /></App>,
  },
  {
    path: "/naturalvoice/aziende",
    element: <App><VoceAziende /></App>,
  },
  {
    path: "/us",
    element: <App><AboutUs /></App>,
  },
  {
    path: "/contacts",
    element: <App><Contacts /></App>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
