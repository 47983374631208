function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="orange">
     <span style={{fontSize: '0.7em', fontWeight: 500}}>Scopri la tua voce naturale</span>
     Sessioni di Voce Cantata
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" data-hc="white">
        La struttura modulare di tutti i nostri corsi &egrave;
        studiata appositamente per essere
        personalizzata su misura per ciascun artista, garantendo un percorso formativo
        unico e adatto alle specifiche esigenze di ognuno
    </div>
  );
}

function Slide3(){
  return (
      <div className="slide slide3 padded" data-hc="orange">
        <div>
            <h1>
                Corsi <br />
                Live & <br />
                Online
            </h1>
        </div>
        <table>
            <tr>
                <td className="type">One2One</td>
                <td className="desc">Sessioni estremamente personalizzate e individuali per sbloccare il tuo potenziale vocale unico, affidandosi alla metodologia Natural Voice&reg;</td>
            </tr>
            {/*<tr>
                <td className="type">Group Classes</td>
                <td>Sessioni personalizzate per gruppi ristretti di massimo 5 persone: un vantaggio per le economie con la stessa precisione e affidabilit&agrave; della metodologia Natual Voice&reg;</td>
            </tr>*/}
            <tr>
                <td className="type">Workshop & Webinar</td>
                <td className="desc">Workshop focalizzati su diverse tematiche, per approfondire la comprensione e migliorare le abilit&agrave; vocali. Segui i nostri canali social per rimanere aggiornato su tutti i nuovi workshop!</td>
            </tr>
            <tr>
                <td className="type">Live & Tour</td>
                <td className="desc">Sessioni individuali per migliorare la gestione della voce live e per affrontare con sicurezza il palco, tenendo in considerazione tutti i vari fattori che possono influenzare
                    l'esibizione: dall'ansia all'alimentazione; dall'interpretazione alla cura della voce.</td>
            </tr>
            <tr>
                <td className="type last">Studio Rec</td>
                <td className="desc last">Preparazione e assistenza alla registrazione in studio er la realizzazione dei brani inediti e cover.</td>
            </tr>
            {/*<tr>
                <td className="type">Masterclass</td>
                <td>Sessioni avanzate tenute da professionisti del settore, durante i quali riceverai consigli personalizzati e approfondirai la tua conoscenza vocale e del settore musicale.</td>
            </tr>
            <tr>
                <td className="type last">Seminari</td>
                <td className="last">Esplora argomenti chiave legati alla voce attraverso i nostri seminari interattivi: dalla tecnica vocale alla performance scenica</td>
            </tr>*/}
        </table>
    </div>
  );
}

function Slide5(){
  return (
    <div className="slide slide2 padded" data-hc="white">
        Libera la tua voce, libera la tua arte.
        In un universo in cui ogni voce &egrave; unica,
        Natural Voice&reg; spezza 
        le catene dei metodi standarizzati consentendoti
        di esplorare e amplificare la tua voce naturale:
        l'indispensabile inizio verso il successo autentico<br /><br />
        Siamo qui per guidarti nel lasciare un'impronta indelebile
    </div>
  );
}

function Slide6(){
    return (
      <div className="slide slide3 padded" data-hc="orange">
        <table className="inverted">
            
            <tr>
                <td>Sessioni One2One o Group Classes da casa tua. Le nostre lezioni online offrono la stessa qualit&agrave; delle lezioni in presenza, con il vantaggio di accorciare le distanze</td>
                <td className="type">Sessioni Online</td>
            </tr>
            <tr>
                <td>
                    Sessioni individuali per migliorare la gestione della voce live e per affrontare con sicurezza il palco, tenendo in considerazione tutti i vari fattori che possono influenzare
                    l'esibizione: dall'ansia all'alimentazione; dall'interpretazione alla cura della voce.
                </td>
                <td className="type">Preparazione Live</td>
            </tr>
            <tr>
                <td>Affrontare un tour significa preparazione fisica, mentale ed emotiva. Assistiamo l'artista dall'alimentazione al riscaldamento vocale; dalla preparazione atletica e quella interpretativa. Un lavoro a 360 gradi.</td>
                 <td className="type">Tour</td>
            </tr>
            <tr>
                <td className="last">Preparazione e assistenza alla registrazione in studio er la realizzazione dei brani inediti e delle cover.</td>
                <td className="type last">Studio di Registrazione</td>
            </tr>
        </table>
    </div>
  );
}

function VoceCantata() {
  return (
    <div id="vocecantata">
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide5()}
      {/*Slide6()*/}
    </div>
  );
}

export default VoceCantata;
