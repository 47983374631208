import './App.css';
import './Mobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faTiktok, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import Header from './components/Header';

function App(props) {
  console.log(props)
  return (
    <div className="App">
      <Header />
      {
        props.children
      }
      <footer>
        <div className="social">
          <a href="https://www.tiktok.com/@marcovolpatovocalcoach"><FontAwesomeIcon icon={faTiktok} /></a>
          <a href="https://www.instagram.com/naturalvoice/"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="https://www.linkedin.com/in/marco-volpato/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
        </div>
        <p>Privacy & Cookie</p>
        <p>&copy; 2024 Natural Voice&reg; di Marco Volpato</p>
        <p>Via Pietro Crespi, 6 - Milano | P.IVA. 04283800243</p>
      </footer>
    </div>
  );
}

export default App;
