function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="white">
     <span style={{fontSize: '0.7em', fontWeight: 500}}>Public Speaking & Comunicazione Efficace</span>
     Sessioni di Team Building
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" data-hc="green">
        <p>
        Comunicare bene &egrave; fondamentale per il successo di
        un'azienda. I nostri corsi di voce offrono un approccio
        unico e brevettato, pensato per potenziare le competenze
        vocali e comunicative, contribuendo a
        raggiungere gli obiettivi aziendali.
        </p>
        <p>
        La voce &egrave; il pi&ugrave; potente strumento<br />
        di crescita per la vostra organizzazione
        </p>
    </div>
  );
}

function Slide3(){
  return (
      <div className="slide slide3 padded" data-hc="green">
          <h1>
          Corsi <br />
          Live & <br />
          Online
          </h1>
          <table>
              <tr>
                  <td className="type">Team Building</td>
                  <td>Sessioni finalizzate a rafforzare il legame tra i membri del team attraverso attivit&agrave; legate alla voce parlata o cantata</td>
              </tr>
              <tr>
                  <td className="type">Public Speaking</td>
                  <td>Sessioni per migliorare le abilit&agrave; di presentazione per una comunicazione efficace e naturale</td>
              </tr>
              <tr>
                  <td className="type">Crescita Personale</td>
                  <td>Sessioni per favorire lo sviluppo individuale e contribuire al successo aziendale</td>
              </tr>
              <tr>
                  <td className="type last">Workshop e Webinar</td>
                  <td className="last">Workshop focalizzati su diverse tematiche per approfondire la comprensione e migliorare la comuncazione. Segui i nostir canali Social per rimanre aggiornato su tutti i nuovi Workshop!</td>
              </tr>
          </table>
    </div>
  );
}

function Slide5(){
  return (
    <div className="slide slide1 padded mobnopad" data-hc="white">
        Il successo della tua Azienda inizia con Natural Voice&reg;
    </div>
  );
}

function VoceAziende() {
  return (
    <div id="voceaziende">
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide5()}
    </div>
  );
}

export default VoceAziende;
