function Slide1(){
  return (
    <div className="slide slide1 padded" data-hc="green">
     <span style={{fontSize: '0.7em', fontWeight: 500}}>Esprimi il tuo potenziale unico</span>
     Sessioni di Voce Parlata
    </div>
  );
}

function Slide2(){
  return (
    <div className="slide slide2 padded" data-hc="white">
        La struttura modulare di tutti i nostri corsi è
        studiata appositamente per essere personalizzata
        su misura per ciascun individuo, garantendo un
        percorso formativo unico e adatto alle specifiche
        esigenze di ognuno.
    </div>
  );
}

function Slide3(){
  return (
      <div className="slide slide3 padded" data-hc="green">
        <div>
            <h1>
                Corsi <br />
                Live & <br /> 
                Online <br />
            </h1>
        </div>
        <table>
            <tr>
                <td className="type">One2One</td>
                <td>Sessioni estremamente personalizzate e individuali. Potrai concentrarti su specifiche aree di miglioramento per avere una voce riconoscibile, pronta e naturale</td>
            </tr>
            {/*<tr>
                <td className="type">Group Classes</td>
                <td>Sessioni collettive personalizzate per massimo 5 pesone con l'obiettivo di imparare a gestire la voce parlata seguendo la filosofia Natural Voice&reg;</td>
            </tr>*/}
            <tr>
                <td className="type">Workshop & Webinar</td>
                <td>
                    Workshop focalizzati su diverse tematiche, per approfondire la comprensione e
                    migliorare la voce parlata. Segui i nostri canali Social per rimanere aggiornato su tutti
                    i nuovi workshop!
                </td>
            </tr>
            {/*<tr>
                <td className="type">Sessioni online</td>
                <td>
                    Abbiamo reso l'apprendimento accessibile ovunque tu sia. Le nostre sessioni online
                    ti consentono di seguire i corsi da casa, mantenendo la stessa qualità di
                    insegnamento dei corsi in presenza.
                </td>
            </tr>*/}
            <tr>
                <td className="type">Sfera Personale</td>
                <td>
                    La tua voce non ti piace? Non ti permette di comunicare come vuoi? I nostri corsi
                    mettono in luce l'importanza dell'espressione emotiva e ti
                    guidano nel percorso per comunicare in maniera naturale.
                </td>
                
            </tr>
            <tr>
                <td className="type">Attori, Doppiatori, Speaker, Telecronisti</td>
                <td>
                    Siamo specializzati anche in questi settori specifici. I nostri corsi sono pensati per
                    coloro che usano molto la voce parlata nel proprio lavoro. Affina le tua abilità di
                    gestione, cura, potenziamento naturale della voce.
                </td>
                
            </tr>
        </table>
    </div>
  );
}

function Slide5(){
  return (
    <div className="slide slide2 padded" data-hc="white">
        <p>
          Libera la tua voce, libera la tua personalità. In un
          universo in cui ogni voce è unica, Natural Voice®
          spezza le catene dei metodi standardizzati,
          consentendoti di esplorare e amplificare la tua
          voce naturale: l'indispensabile inizio verso il
          successo autentico.
        </p>
        <p>
          Siamo qui per guidarti nel
          lasciare un'impronta indelebile.
        </p>
    </div>
  );
}


function VoceParlata() {
  return (
    <div id="voceparlata">
      {Slide1()}
      {Slide2()}
      {Slide3()}
      {Slide5()}
    </div>
  );
}

export default VoceParlata;
