import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faXmark, faX, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';


function Header(props) {
    const currentp = useLocation().pathname.replace(new RegExp('/', 'g'), "")
    const [showMenu, setShowMenu] = useState(false);
    const [showActivitiesGroup, setShowActivitiesGroup] = useState(false);
    const [headerClass, setHeaderClass] = useState("");

    const getHeaderClass = () => {
        if(showMenu){
            return "headerWithNavMenu";
        }
                
        const elems = document.querySelectorAll("video, .slide");
        const padding = (window.innerHeight/100)*10;
        let currentIntersect = elems[0];
        for (const e of elems) {
            let d = e.getBoundingClientRect();
            //console.log(window.scrollY)
            //console.log(d)
            //console.log(d.height+d.y)
            if(d.height+d.y-padding >= 0){
                currentIntersect = e;
                break;
            }
        }
        
        try{
            return currentIntersect.attributes.getNamedItem('data-hc').value + " " + currentp;
        } catch (err){
            return null;
        }
    }

    useEffect(() => {
        const onScroll = () => {
            if(showMenu){
                setHeaderClass("headerWithNavMenu");
            }else{
                setHeaderClass(getHeaderClass() || "")
            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [showMenu]);

    useEffect(() => {
        if(showMenu){
            setHeaderClass("headerWithNavMenu");
        }else{
            setHeaderClass(getHeaderClass() || "")
        }
        
    }, [showMenu])

    function toggleMenu(event){
        event.preventDefault();
        setShowMenu(!showMenu);
    }
    
    function toggleActivitiesGroup(event){
        event.preventDefault();
        setShowActivitiesGroup(!showActivitiesGroup)
    }
    /*console.log(slideNum)
    console.log(style);*/
    
    return (
        <div className="header">
            {
                showMenu &&
                <div className="navMenu">
                    <div><a href="/">Homepage</a></div>
                    <div><a href="/naturalvoice">Natural Voice&reg;</a></div>
                    <div onClick={(e) => toggleActivitiesGroup(e)}>
                        <>
                        <span style={{ cursor: "pointer"}}>Cosa facciamo&nbsp; 
                            <>
                            {
                                !showActivitiesGroup && <FontAwesomeIcon style={{fontSize: "0.5em", paddingBottom: "0.2em"}} icon={faCaretDown} />
                            }
                            {
                                showActivitiesGroup && <FontAwesomeIcon style={{fontSize: "0.5em", paddingBottom: "0.2em"}} icon={faCaretUp} />
                            }
                            </>
                        </span>
                        </>
                    </div>
                        
                    <div className={"activities-group " + (showActivitiesGroup ? 'visible' : '')}>
                        <div className="paddedItem"><a href="/naturalvoice/cantata">Voce Cantata</a></div>
                        <div className="paddedItem"><a href="/naturalvoice/parlata">Voce Parlata</a></div>
                        <div className="paddedItem"><a href="/naturalvoice/aziende">Voce per le Aziende</a></div>
                    </div>
                    <div><a href="/us">About us</a></div>
                    <div><a href="/contacts">Get in touch</a></div>
                </div>
            }
            
            
            <header className={headerClass}>
                <div>
                    <img className="logo" onClick={(e) => toggleMenu(e)} src="/logo.svg" />
                    
                    <div className="bars" onClick={(e) => toggleMenu(e)}>
                        {
                            !showMenu && <FontAwesomeIcon icon={faBars} />
                        }
                        {
                            showMenu && <FontAwesomeIcon icon={faX} />
                        }
                        
                    </div>
                    <div className="clear"></div>
                </div>
            </header>
        </div>
    )
}
    
export default Header;